<template>
  <div id="home">
    <van-nav-bar
        title="钱包明细"
        :left-arrow="true"
        @click-left="goback('/ucenter')"
        fixed
    />
    <div class="login wallets">
      <div class="wallet">
        <div class="my">当前余额</div>
        <div class="amount">{{totalprice}}</div>
        <div class="btns">
          <div class="box">
            <van-button plain round size="small" @click.stop="toRecharge">余额充值</van-button>
          </div>
          <div class="box">
            <van-button plain round size="small" @click.stop="toFundlogs">余额明细</van-button>
          </div>
        </div>
      </div>
      <van-list class="auditlist" style="padding-top: 3px;"
                v-model:loading="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="getlist">
        <van-cell v-for="(order,idx) in orderlist" :key="idx">
          <div class="title">
            <div class="sn i-copy" @click="copySn(order.order_sn)">{{ order.order_sn }} <van-tag>复制</van-tag> </div>
            <div :class="order.pay_status == 1 ? 'amount success' :'amount'">+ {{ order.price }}</div>
          </div>
          <div class="info">
            <div class="time">{{ order.pay_status == 0 ? order.created_at : order.pay_time }}</div>
            <div class="status">
              <span v-if="order.pay_status == 0">待支付</span>
              <span class="success" v-else>已支付</span>
            </div>
          </div>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import {onMounted, reactive, toRefs} from 'vue';
import {httpget} from "@/libs/api";
import {Toast} from "vant";
import {useRouter} from "vue-router";
import Clipboard from "clipboard";

export default {
  name: 'recharge',
  setup() {
    const router = useRouter();
    const state = reactive({
      loading:false,
      finished:false,
      totalprice:'0.00',
      searchinfo:{
        page:0,
      },
      orderlist:[],
    })
    const copySn = (text) =>{
      let clipboard = new Clipboard(".i-copy",{
        text: function () {
          return text;
        }
      });
      clipboard.on("success", function(){
        Toast.success({message:'已复制', duration:500});
        clipboard.destroy();
      });
      clipboard.on("error", function(){
        Toast.success('浏览器不支持复制');
        clipboard.destroy();
      });
    }
    const getlist = async () =>{
      state.loading = true;
      if(!state.finished){
        state.searchinfo.page++;
      }
      let res = await httpget('/api/recharge/index',state.searchinfo);
      if(res.code===0){
        state.loading = false;
        if(state.searchinfo.page==1){
          state.orderlist = [];
        }
        if(res.data.data.length>0){
          res.data.data.forEach((task)=>{
            state.orderlist.push(task);
          })
        }
        if(res.data.data.length<20){
          state.finished = true;
        }
      }else{
        state.loading = false;
        state.finished = true;
        return ;
      }
    }
    const gettotalprice = async () =>{
      let res = await httpget('/api/recharge/gettotal');
      if( res.code == 0 ){
        state.totalprice = res.data;
      }
    }
    const toRecharge = () =>{
      router.push({path:'/wallet'}).catch((err)=>err);
    }
    const toFundlogs = () => {
      router.push({path:'/fundlogs'}).catch((err)=>err);
    }
    onMounted(() => {
      getlist();
      gettotalprice();
    })

    return {
      ...toRefs(state),
      getlist,
      copySn,
      gettotalprice,
      toRecharge,
      toFundlogs
    };
  },
}
</script>
